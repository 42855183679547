<template>
  <div class="management bj">
    <div class="contents">
      <el-form
        :model="ruleForm"
        label-width="110px"
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
      >
        <el-form-item label="旧密码" prop="password">
          <div class="inputs">
            <el-input
              v-model="ruleForm.password"
              placeholder="请输入旧密码"
              clearable
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item label="密码" prop="new_password">
          <div class="inputs">
            <el-input
              v-model="ruleForm.new_password"
              placeholder="请输入密码"
              clearable
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item label="确认密码" prop="confirm_password">
          <div class="inputs">
            <el-input
              v-model="ruleForm.confirm_password"
              placeholder="请输入确认密码"
              clearable
            ></el-input>
          </div>
        </el-form-item>
        <div style="color: red" class="mb-10">
          新密码需为数字、大写字母、小写字母、或者符号
          （!@#$%^&*()_+-={}[]|:;<>,.?~）中的至少三种的至少12位的组合；
        </div>
      </el-form>
      <div style="margin-left: 110px">
        <el-button type="primary" @click="tianku">确认</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "changePassword",
  components: {},
  data() {
    return {
      //列表新建页面初始参数
      loading: false,
      //列表页面不同功能参数
      ruleForm: {
        password: "", // 原密码
        new_password: "", //新密码
        confirm_password: "", //确认密码
      },
      rules: {
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        new_password: [
          { required: true, message: "请输入新密码", trigger: "blur" },
        ],
        confirm_password: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
        ],
      },
    };
  },
  created() {},
  methods: {
    tianku() {
      console.log(this.ruleForm);
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          try {
            const res = await this.$api.login.editPassword(this.ruleForm);
            console.log(res, "res===");
            this.$message({
              type: "success",
              message: "修改成功",
            });
            this.$router.push({
              path: "/meResume",
            });
          } catch (error) {
            console.log(error, "editPassword==");
          }
        }
      });
    },
  },
};
</script>
<style lang="scss">
.management {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;

  .contents {
    padding-left: 55px;
    padding-right: 55px;
    padding-top: 23px;

    box-sizing: border-box;
  }
}

.inputs {
  width: 300px;
}
</style>
