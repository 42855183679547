var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "management bj" }, [
    _c(
      "div",
      { staticClass: "contents" },
      [
        _c(
          "el-form",
          {
            ref: "ruleForm",
            staticClass: "demo-ruleForm",
            attrs: {
              model: _vm.ruleForm,
              "label-width": "110px",
              rules: _vm.rules,
            },
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "旧密码", prop: "password" } },
              [
                _c(
                  "div",
                  { staticClass: "inputs" },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入旧密码", clearable: "" },
                      model: {
                        value: _vm.ruleForm.password,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "password", $$v)
                        },
                        expression: "ruleForm.password",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "el-form-item",
              { attrs: { label: "密码", prop: "new_password" } },
              [
                _c(
                  "div",
                  { staticClass: "inputs" },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入密码", clearable: "" },
                      model: {
                        value: _vm.ruleForm.new_password,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "new_password", $$v)
                        },
                        expression: "ruleForm.new_password",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "el-form-item",
              { attrs: { label: "确认密码", prop: "confirm_password" } },
              [
                _c(
                  "div",
                  { staticClass: "inputs" },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入确认密码", clearable: "" },
                      model: {
                        value: _vm.ruleForm.confirm_password,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "confirm_password", $$v)
                        },
                        expression: "ruleForm.confirm_password",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c("div", { staticClass: "mb-10", staticStyle: { color: "red" } }, [
              _vm._v(
                " 新密码需为数字、大写字母、小写字母、或者符号 （!@#$%^&*()_+-={}[]|:;<>,.?~）中的至少三种的至少12位的组合； "
              ),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { "margin-left": "110px" } },
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.tianku } },
              [_vm._v("确认")]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }